import React from "react";
import PropTypes from "prop-types";
import Cursor from "~components/Cursor";

const Layout = ({ children, className }) => {
  // if (typeof window !== `undefined`) {
  //   window.location.href = `https://exhibition.with-you.studio/`;
  // }

  //

  return (
    <>
      <Cursor />

      {/* <Header /> */}

      {/* <Nav /> */}

      <main id="layout" className={`layout ${className} relative bg-white`}>
        {children}
      </main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired
};

export default Layout;
