import React, { useContext } from "react";
import { Link } from "gatsby";
import { DocumentContext } from "~context/DocumentContext";
import Wordmark from "~components/svg/Wordmark";
import CursorHoverable from "~components/CursorHoverable";

const Footer = () => {
  const { device } = useContext(DocumentContext);

  return (
    <footer className="footer w-full relative bg-black text-white">
      <div className="w-full relative flex xs:flex-col items-end xs:items-center justify-between xs:justify-center p-v3">
        <div className="xs:order-1 xs:mt-v4">
          <CursorHoverable>
            <a
              href="https://www.with-you.studio/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <Wordmark
                className="footer__logo xs:mt-v6 xs:mb-v2"
                color="white"
              />
            </a>
          </CursorHoverable>

          <h4
            className={`${
              device && device === `desktop` ? `caption` : `b1 text-center`
            } xs:mb-v16`}
          >
            © 2020
          </h4>
        </div>

        <ul className="relative flex xs:block xs:pt-v16 xs:text-center">
          <li
            className={`footer__link mr-v2 xs:pt-v2 xs:pb-v2 ${
              device && device === `desktop` ? `caption` : `b1`
            }`}
          >
            <CursorHoverable>
              <Link to="/how-it-works" className="hover-underline">
                How Our Exhibitions Work
              </Link>
            </CursorHoverable>
          </li>

          {/* <li
            className={`footer__link mr-v2 xs:pt-v2 xs:pb-v2 ${
              device && device === `desktop` ? `caption` : `b1`
            }`}
          >
            <CursorHoverable>
              <Link to="/faqs" className="hover-underline">
                FAQs
              </Link>
            </CursorHoverable>
          </li> */}

          {/* <li
            className={`footer__link mr-v2 xs:pt-v2 xs:pb-v2 ${
              device && device === `desktop` ? `caption` : `b1`
            }`}
          >
            <CursorHoverable>
              <Link to="/terms-and-conditions" className="hover-underline">
                Terms &amp; Conditions
              </Link>
            </CursorHoverable>
          </li> */}

          {/* <li
            className={`footer__link mr-v2 xs:pt-v2 xs:pb-v2 ${
              device && device === `desktop` ? `caption` : `b1`
            }`}
          >
            <CursorHoverable>
              <Link to="/privacy" className="hover-underline">
                Privacy Policy
              </Link>
            </CursorHoverable>
          </li> */}

          {/* <li
            className={`footer__link mr-v4 xs:pt-v2 xs:pb-v2 ${
              device && device === `desktop` ? `caption` : `b1`
            }`}
          >
            <CursorHoverable>
              <a
                href="https://www.with-you.studio/contact"
                rel="noopener noreferrer"
                target="_blank"
              >
                Contact
              </a>
            </CursorHoverable>
          </li> */}

          <li className="desktop-only caption">
            <span>Built with{` `}</span>
            <CursorHoverable className="inline-block">
              <a
                href="https://www.instagram.com/sumvalueadded/"
                rel="noopener noreferrer"
                target="_blank"
                className="underline"
              >
                Sum Value Added
              </a>
            </CursorHoverable>
          </li>
        </ul>
      </div>

      <h4 className="touch-only b2 xs:text-center">
        <span>Built with{` `}</span>
        <CursorHoverable className="inline-block">
          <a
            href="https://www.instagram.com/sumvalueadded/"
            rel="noopener noreferrer"
            target="_blank"
            className="underline"
          >
            Sum Value Added
          </a>
        </CursorHoverable>
      </h4>
    </footer>
  );
};

export default Footer;
